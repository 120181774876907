import { DIALOGS_PRICING_FRAGMENT } from '@utils/Data/Pricing/queries';
import gql from 'graphql-tag';
import { FULL_NATIVE_BOT_FRAGMENT } from '../../common/services/GQLqueries/BotGQService.const';

export const FACEBOOK_PAGES_BOT_FRAGMENT = gql`
  fragment facebookPagesBotFragment on Bot {
    id
    title
    landing {
      enabled
      path
    }
    status {
      status
      page
      page_info {
        id
        title
        picture
        username
        eu_restricted
        owner {
          id
          name
          picture
        }
        app {
          id
          is_chatfuel_app
        }
      }
    }
    pro {
      status
      status
      debt
      days_before_cancel
      millis_before_cancel
      manual
    }
  }
`;

export const FACEBOOK_PAGES_LIST_FRAGMENT = gql`
  fragment facebookPagesListFragment on Query {
    pages(skipRefresh: $skipRefresh) {
      id
      title
      picture
      bot_id
      workspace_id
      pro
      archiving_status
      whitelabeled
      currentUserBot {
        id
        title
      }
    }
  }
`;

const FACEBOOK_PAGES_BOT_PRO_DATA_FRAGMENT = gql`
  fragment facebookPagesBotProDataFragment on Bot {
    pro {
      status
      manual
    }
    paymentInformation {
      nextBillingDate
    }
  }
`;

export const FACEBOOK_PAGES_SETUP_QUERY = gql`
  query FacebookPagesSetupQuery($botId: String!, $skipRefresh: Boolean) {
    me {
      id
      fbPermissions {
        hasManagePagesPermissions
        hasMinimalPermissions
      }
      facebook_account_connected
    }
    bot(id: $botId) {
      ...facebookPagesBotFragment
      ...facebookPagesBotProDataFragment
    }
    ...facebookPagesListFragment
  }
  ${FACEBOOK_PAGES_BOT_FRAGMENT}
  ${FACEBOOK_PAGES_LIST_FRAGMENT}
  ${FACEBOOK_PAGES_BOT_PRO_DATA_FRAGMENT}
`;

const FACEBOOK_PAGES_PRICING_FRAGMENT = gql`
  fragment facebookPagesPricingFragment on Bot {
    pricingData {
      currency
      pricing
      currentUsers
      reached_limit
      currentMaxUsers
      currentPrice
      needPrice
    }
  }
`;

export const BIND_BOT_TO_PAGE_MUTATION = gql`
  mutation BindBotToPageMutation(
    $botId: String!
    $pageId: String!
    $features: [String!]!
    $firstSession: Boolean
  ) {
    bindBotToPage(botId: $botId, pageId: $pageId, firstSession: $firstSession) {
      ...nativeBotFragment
      ...facebookPagesBotFragment
      ...facebookPagesPricingFragment
      dialogsPricing {
        ...dialogsPricingFragment
      }
      verifiedPermissions(features: $features) {
        id
        enabled
        permissions_group
      }
      features {
        flow_stats
      }
    }
  }
  ${FULL_NATIVE_BOT_FRAGMENT}
  ${FACEBOOK_PAGES_BOT_FRAGMENT}
  ${FACEBOOK_PAGES_PRICING_FRAGMENT}
  ${DIALOGS_PRICING_FRAGMENT}
`;

export const UN_BIND_PAGE_MUTATION = gql`
  mutation UnBindPageMutation($pageId: String!) {
    unBindPage(pageId: $pageId) {
      id
      bot_id
      currentUserBot {
        id
        title
      }
    }
  }
`;
