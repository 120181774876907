import { initialWebAppParams } from './initialWebAppParams';
import { LIVE_CHAT_WEB_VIEW } from './onBeforeBotPageRender';
import { isSwitchFromAwesomePanelSession } from './switchFromAwesomePanel';
import { isLiveChatWebView } from './useIsLiveChatWebView';

export const isNeedForceAuth = () => {
  const isLiveChatWebViewAuth = initialWebAppParams.has(LIVE_CHAT_WEB_VIEW);
  const isInvitePage = window.location.pathname.startsWith('/invite');
  const isWhiteLabelInvitePage =
    window.location.pathname.startsWith('/invite-page');

  return (
    isSwitchFromAwesomePanelSession() ||
    isLiveChatWebViewAuth ||
    isLiveChatWebView() ||
    isInvitePage ||
    isWhiteLabelInvitePage
  );
};
