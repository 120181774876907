import React from 'react';
import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { LS, LSKey } from 'cf-common/src/localStorage';
import memoize from 'lodash-es/memoize';
import { initialWebAppParams } from './initialWebAppParams';
import { Modal } from '@services/index';
import { RedirectToLiveChatDialog } from '@components/RedirectToLiveChatDialog/RedirectToLiveChatDialog';

export const LIVE_CHAT_WEB_VIEW = 'livechat-webview';

const getAppLink = (token: string) => `chatfuel://auth?auth=${token}`;

const checkToRedirectLiveChatWebView = memoize(() => {
  if (!initialWebAppParams.has(LIVE_CHAT_WEB_VIEW)) return;

  const link = document.createElement('a');

  const token = LS.getRaw(LSKey.token);
  if (!token) return;

  link.href = getAppLink(token);

  link.click();
  link.remove();
});

export const showRedirectToLiveChatModal = memoize(() => {
  if (!initialWebAppParams.has(LIVE_CHAT_WEB_VIEW)) return;

  Modal.show(
    ({ close }) => (
      <RedirectToLiveChatDialog close={close} getAppLink={getAppLink} />
    ),
    {
      id: 'RedirectToLiveChatDialog',
    },
  );
});

export const onBeforeBotPageRender = () => {
  removeGlobalLoader();
  checkToRedirectLiveChatWebView();
};
