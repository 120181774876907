import { useQuery } from '@apollo/react-hooks';
import { QueryHookOptions } from 'react-apollo';
import {
  FacebookPagesSetupQuery,
  FacebookPagesSetupQueryVariables,
} from './@types/FacebookPagesSetupQuery';
import { FACEBOOK_PAGES_SETUP_QUERY } from './queries';
import { useCallback, useEffect, useRef } from 'react';
import { LS, LSKey } from 'cf-common/src/localStorage';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ServiceMessageType, toaster } from '@services/MessageService';

export type FacebookPagesHookPayload = ReturnType<typeof useFacebookPages>;

const DISABLE_REFRESH_TOASTER_LIFETIME_MS = 10 * 1000;
const DISABLE_REFRESH_TIMEOUT_MS = 55 * 1000;
const DISABLE_REFRESH_LS_TTL_MS = 60 * 1000 * 30;

const isRefreshDisabled = () => {
  const currentTtl = LS.get(LSKey.facebookPagesDisabledRefreshTtlTimestamp);
  if (!currentTtl || currentTtl < Date.now()) {
    LS.remove(LSKey.facebookPagesDisabledRefreshTtlTimestamp);
    return false;
  }
  return true;
};

export const useFacebookPages = (
  botId: string | undefined,
  options?: QueryHookOptions<
    FacebookPagesSetupQuery,
    FacebookPagesSetupQueryVariables
  >,
) => {
  const { t } = useSafeTranslation();
  const skipRefreshRef = useRef(isRefreshDisabled());
  const timerRef = useRef<undefined | NodeJS.Timeout>();

  const { data, loading, error, called, refetch } = useQuery<
    FacebookPagesSetupQuery,
    FacebookPagesSetupQueryVariables
  >(FACEBOOK_PAGES_SETUP_QUERY, {
    variables: { botId: botId!, skipRefresh: skipRefreshRef.current },
    skip: !botId,
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  useEffect(() => {
    if (!skipRefreshRef.current && loading && called) {
      timerRef.current = setTimeout(() => {
        LS.set(
          LSKey.facebookPagesDisabledRefreshTtlTimestamp,
          Date.now() + DISABLE_REFRESH_LS_TTL_MS,
        );
        skipRefreshRef.current = false;

        toaster.show({
          type: ServiceMessageType.default,
          payload: {
            message: t('FacebookPagesLongLoading.message'),
            timeout: DISABLE_REFRESH_TOASTER_LIFETIME_MS,
          },
        });
      }, DISABLE_REFRESH_TIMEOUT_MS);
    }

    if (called && !loading && timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [loading, called, t, refetch]);

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    [],
  );

  const facebookPagesRefetch = useCallback(
    (variables?: FacebookPagesSetupQueryVariables | undefined) => {
      LS.remove(LSKey.facebookPagesDisabledRefreshTtlTimestamp);
      skipRefreshRef.current = false;
      refetch(variables);
    },
    [refetch],
  );

  return {
    facebookPagesData: data,
    facebookPagesLoading: loading,
    facebookPagesCalled: called,
    facebookPagesError: error,
    facebookPagesRefetch,
  };
};
